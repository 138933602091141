<template>
    <div class="test-drive-create">
      <SelectShop :disabledMark="fromClueFollow"></SelectShop>
      <div class="test-drive-schedule">
        <div v-if="!fromClueFollow" class="card">
            <div class="title">{{$t('试驾用户')}}</div>
            <div class="content">
                <van-form ref="userRef" input-align="right" error-message-align="right">
                  <van-popover
                    v-model="showPopover"    
                    placement='bottom-end'                
                    style="width: 100%;"
                  >
                    <div class="popover-action">
                      <div class="popover-a-item">
                        <div class="popover-a-item-label">顾客姓名</div>
                        <div>{{ popoverUser.userName }}</div>
                      </div>
                      <div class="popover-a-item">
                        <div class="popover-a-item-label">手机号</div>
                        <div>{{popoverUser.userMobile}}</div>
                      </div>
                      <div class="popover-a-item">
                        <div class="popover-a-item-label">跟进人</div>
                        <div>{{popoverUser.followUserName  }}</div>
                      </div>
                      <van-button class="btn" type="primary" @click.stop="mobileConfirm">{{$t('确认')}}</van-button>
                    </div>
                    <template #reference>
                      <van-field v-model.trim="formData.customerMobile" 
                        :label="$t('手机号')"
                        name="customerMobile"
                        :placeholder="$t('请输入')"
                        type="number"
                        required
                        :formatter="(value)=> value.replace(/\s+/g, '')"
                        :rules="[{ required: true, trigger: 'onBlur' },showPopover?{}:validate.mustPhone]"
                      />
                    </template>
                  </van-popover>
                    
                    <van-field v-model.trim="formData.customerName"
                      :label="$t('客户姓名')"
                      name="customerName"
                      :placeholder="$t('请输入')"
                      maxlength="20"
                      required
                      :rules="[{ required: true, trigger: 'onBlur' }]"
                    />
                </van-form>
            </div>
        </div>
        <div v-if="!fromClueFollow" class="card">
            <div class="title">{{$t('试驾场景')}}</div>
            <div class="content">
                <van-form ref="formRef" input-align="right" error-message-align="right">
                  <van-field
                    name="typeCode"
                    :label="$t('试驾场景')"
                    required
                    :rules="[{required: true,message:$t('请选择')}]"
                    error-message-align="right"
                    input-align="right">
                    <template #input>
                      <van-radio-group
                        v-model="formData.typeCode"
                        icon-size="32px"
                        class="custom-radio-group-wrap"
                      >
                        <van-radio v-for="item in driveTypeList" :key="item.dictCode" :name="item.dictCode" class="custom-radio-wrap">
                          <template #icon="props">
                            <div class="custom-radio" :class="{ checked: props.checked, typeCheck: props.checked }" @click.stop="onChangeDrive(item)">{{item.dictName}}</div>
                          </template>
                        </van-radio>
                      </van-radio-group>
                    </template>
                  </van-field>
                  <!-- 试驾信息 上门试驾时展示 -->
                  <template v-if="formData.typeCode==='2002002'">
                    <AddressSystem ref="addressSystem"
                      :label="$t('试驾地点')"
                      :keys="addressKeys"
                      :form-data="addressObj"
                      :placeholder="$t('请选择')"
                      :inputAlign="'right'"
                      :isRequired="true"
                      :rules="[{required:true}]"
                      @change="onAddressChange">
                    </AddressSystem>
                    <van-field v-model="formData.driveAddress"
                      :label="$t('详细地址')"
                      required
                      :placeholder="$t('请输入')"
                      maxlength="50"
                      :rules="[{required:true}]"/>
                  </template>
                  <!-- 试驾信息  集中试驾时展示 -->
                  <template v-if="formData.typeCode==='2002003'">
                    <van-field v-model="formData.activityTitle"
                      :label="$t('活动名称')"
                      readonly
                      required
                      :right-icon="'arrow'"
                      input-align="right"
                      :placeholder="$t('请选择')"
                      :rules="[{required:true}]"
                      @click="onClickActivityTitle"
                      />
                    <van-field v-model="formData.seriesName"
                      :label="$t('预约车系')"
                      readonly
                      required
                      :right-icon="'arrow'"
                      input-align="right"
                      :placeholder="$t('请选择')"
                      :rules="[{required:true}]"
                      @click="isShowActivitySeries = true"
                      />
                  </template>
                </van-form>
            </div>
        </div>
        <div v-if="formData.typeCode!=='2002003'" class="card">
            <div class="title">{{$t('试驾车型')}}</div>
            <div class="content">
              <van-form ref="carInfo" input-align="right" error-message-align="right">
                <van-field
                  key="carInfo"
                  :label="$t('试驾车型')"
                  :placeholder="$t('请选择试驾车型')"
                  :value="carInfo | formatCarInfo"
                  right-icon="arrow"
                  type="textarea"
                  rows="1"
                  autosize
                  readonly
                  required
                  :rules="[{ required: true, trigger: 'onBlur' }]"
                  @click="selectCarModel"
                />
              </van-form>
              <div v-if="formData.vehicleCode&&formData.typeCode!=='2002002'" class="car-list">
                <van-tag v-for="car in carList" :key="car.vinCode" type="primary" size="large" :class="['car-item',formData.vinCode === car.vinCode?'car-selected':'']" @click="onSelectCar(car)">
                  {{ car.carNumber }}
                </van-tag>
                <span v-if="formData.vehicleCode&&!carList.length">
                  <van-icon name="warning-o" color="red" />
                  {{$t('暂无可用车辆')}}
                </span>
              </div>
            </div>
        </div>
        <div class="card">
            <div class="title">{{$t('试驾时间')}}</div>
            <div class="content">
              <CalendarLine v-if="defaultDay" ref="calendarRef" :defaultDay="defaultDay" :calendarValue="appointNumMap" :activeDateRange="formData.typeCode==='2002003'?activeDateRange:[]" style="margin-bottom: 24px;" @changeTime="changeDate"/>
              <div class="container">
                <div class="timeBox">
                    <div v-for="(time,idx) in timetable" :key="time" class="time">{{ time }}</div>
                </div>
                <div class="placeholder">
                    <div v-for="(time,idx) in timetable" :key="time" :class="['hour',overTimeList.includes(time)?'over-time':'']" @click="selectHour(time,idx)"></div>
                    <!-- 禁选时间 -->
                    <div v-for="(item,idx) in appointmentTimeList" :key="idx" class="time-disabled" :style="{top:item.top+'px',left:item.left+'px',height:item.height+'px'}">
                      <div class="ellipsis">{{ item.customerName }}</div>
                      <div class="ellipsis">{{ item.typeName }}</div>
                    </div>
                    <!-- 选中时间 -->
                    <div v-if="selectedTime.length" id="dragBox" class="time-selected"></div>
                </div>
                
              </div>
            </div>
        </div>
        <div class="common-footer">
          <van-button class="btn" @click="onCancel">{{$t('取消')}}</van-button>
          <van-button class="btn" type="primary" @click="onConfirm">{{$t('确认')}}</van-button>
        </div>
        <!-- 集中试驾活动 -->
        <van-action-sheet v-model="isShowActivityList" :actions="activityList" @select="onActivitySelect" />
        <!-- 集中试驾车系 -->
        <van-action-sheet v-model="isShowActivitySeries" :actions="activitySeriesList" @select="onActivitySeriesSelect" />
      </div>
    </div>
  </template>
<script>
import baseDataServices from '@/services/baseDataServices'
import loading from '@/utils/loading'
import clueServices from '@/services/clueServices'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import CalendarLine from '@/components/calendar-line/index.vue'
import AddressSystem from '@/components/addressSystem.vue'
import CarManageServices from '@/services/carManageService.js'
import { addTestDrive } from '@/services/appointment.js'
import { getCalendarList } from '@/services/appointment'
import { validate } from '@/utils/validate'
import { getAppointmentDay } from '@/services/appointment'
import SelectShop from '@/components/SelectShop/index.vue'
import selectCar from '@/components/select-car'
    
export default {
  components:{ CalendarLine,AddressSystem,SelectShop },
  filters: {
    formatCarInfo(info) {
      const { seriesName, modelName } = info
      let display = ''
      seriesName && (display += ` ${seriesName}`)
      modelName && (display += ` ${modelName}`)
      return Object.keys(info).length === 0
        ? null
        : display
    },
  },
  props: {
    fromClueFollow:{
      type:Boolean,
      default:false
    },
    data: {
      type: Object,
      default: () =>{}
    }
  },
  data(){
    return {
      defaultDay: '',
      validate: validate,
      formData: {
        appointmentTypeCode: '2001001',
        leadId: '',
        online: '',
        customerMobile: '',
        customerName: '',
        typeCode: '',
        testDriveActivityId: '', // 试驾活动 id
        activityTitle: '',
        driveAddress: '',
        driveCityCode: '',
        driveCityName: '',
        driveDistrictCode: '',
        driveDistrictName: '',
        driveProvinceCode: '',
        driveProvinceName: '',
        carNumber: '',
        vinCode: '',
        seriesName: '',
        seriesNameEn: '',
        seriesCode: '',
        vehicleCode: '',
        vehicleName: '',
        vehicleNameEn: '',
        appointmentStartTime: '',
        appointmentEndTime: '',
      },
      carInfo: {}, // 试驾车型
      judgeExists: false,
      refName: 'calendar-i',
      timetable: [
        '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30',
        '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30',
        '17:00', '17:30', '18:00',
        '18:30', '19:00', '19:30', '20:00', '20:30','21:00', '21:30', '22:00', '22:30', '23:00', '23:30'
      ],
      selectedTime: [],
      carList: [], // 车
      appointNumMap: {},
      addressKeys:{
        cityCode:'driveCityCode',
        city:'driveCityName',
        provinceCode:'driveProvinceCode',
        province:'driveProvinceName',
        countyCode:'driveDistrictCode',
        county:'driveDistrictName',
      },
      addressObj:{},
      isShowActivityList: false,
      activityList: [],
      activitySeriesList: [], // 集中试驾车系列表
      isShowActivitySeries: false,
      activeDateRange: [],
      appointmentTimeList: [], // 已预约的时间段
      showPopover: false,
      popoverUser:{},
      overTimeList: []
    }
  },
  computed: {
    ...mapGetters([ 'userInfo', 'selectedShopId']),
    driveTypeList() {
      return [
        { dictCode: '2002001', dictName: this.$t('到店试驾') },
        { dictCode: '2002002', dictName: this.$t('上门试驾') },
        { dictCode: '2002003', dictName: this.$t('集中试驾') },
      ]
    },
  },
  watch:{
    'formData.customerMobile'(value) {
      if (value && value.length<11){
        this.getMobileToClues(value)
      } else {
        this.showPopover = false
      }
      if (/^1[3456789]\d{9}$/.test(value)) {
        this.baseClueInfoFilled('tel')
      }
    },
    selectedShopId: {
      handler(val, oldVal) {
        if (oldVal && val !== oldVal) {
          this.formData = this.$options.data().formData
          this.popoverUser = this.$options.data().popoverUser
        }
      }
    }
  },
  async mounted(){
    // 回显选中的数据
    if (this.fromClueFollow && this.data.vehicleCode){
      const { appointmentStartTime,appointmentEndTime,typeCode,appointmentTypeCode } = this.data
      this.formData.appointmentStartTime = appointmentStartTime
      this.formData.appointmentEndTime = appointmentEndTime
      this.formData.typeCode = typeCode
      this.formData.appointmentTypeCode = appointmentTypeCode
      this.onSelectModel(this.data)
    } else {
      this.onSelectCar({})
    }
    this.defaultDay = this.formData.appointmentStartTime?dayjs(this.formData.appointmentStartTime).format('YYYY-MM-DD'):dayjs().format('YYYY-MM-DD')
    if (dayjs().format('YYYY-MM-DD')===this.defaultDay){
      // 获取过时的时间
      this.getOverTimeList()
    }
  },
  methods:{
    // 获取过时的时间
    getOverTimeList(){
      const now = dayjs()
      const today = dayjs().format('YYYY-MM-DD')
      const list = []
      this.timetable.forEach(time=>{
        if (!dayjs(`${today} ${time}:00`).isAfter(now,'second')){
          list.push(time)
        }
      })
      this.overTimeList = list
    },
    getMobileToClues(userMobile='') {
      clueServices.getMobileToClues({ userMobile }).then(res=>{
        if (res && res.lead){
          this.popoverUser = res.lead
          this.showPopover = true
        } else {
          this.showPopover = false
        }
      })
    },
    mobileConfirm() {
      this.formData.customerMobile = this.popoverUser.userMobile
      this.showPopover = false
    },
    // 通过电话号码判重
    async baseClueInfoFilled(type) {
      if (this.judgeExists) return false
      this.judgeExists = true
      try {
        loading.showLoading(this.$t('线索验证中'))
        const {
          customerMobile,
        } = this.formData
        const record = await clueServices.clueExist({
          userMobile: customerMobile,
          dealerId: this.userInfo.dealers[0]?.id,
        })
        this.judgeExists = false
        loading.hideLoading()
        this.handleLink(record, type)
      } catch (error) {
        loading.hideLoading()
        this.judgeExists = false
        this.$toast.fail(error.message)
      }
    },
    // 查重结果处理
    handleLink(data, type) {
      const {
        followUserId,
        followUserName,
        id,
        online,
        isRecycle,
        followUserRoleCode,
        userName
      } = data
      if (!id) {
        this.formData.leadId = ''
        this.formData.online = ''
        this.formData.customerName = ''
        return false
      }
      if (isRecycle == 0) {
        // 未被回收的逻辑
        // 无跟进人 提示：该线索还未分配跟进人，是否领取？
        if (!followUserId) {
          this.$dialog
            .confirm({
              title: this.$t('提示'),
              message: this.$t('该线索还未分配跟进人，是否领取 ？'),
              confirmButtonColor: '#B9921A',
              confirmButtonText: this.$t('领取'),
            })
            .then(async () => {
              const result = await clueServices.receiveClue({ id })
              if (result) {
                this.$toast(this.$t('领取成功'))
                this.formData.customerName = userName
                this.formData.leadId = id
                this.formData.online = online
              }
            })
        }
        // 跟进人是自己或他人  都直接回显名称
        if (followUserId) {
          this.formData.customerName = userName
          this.formData.leadId = id
          this.formData.online = online
        }
      } else if (isRecycle == 1) {
        // 回收的逻辑
        if (followUserId === this.userInfo.id) {
          this.$dialog
            .confirm({
              title: this.$t('提示'),
              message: this.$t('请联系master 或者 店长将线索分配到自己。'),
              showCancelButton: false,
              confirmButtonColor: '#B9921A',
              confirmButtonText: this.$t('确认'),
            })
            .then(() => {})
            .finally(() => {
              this.formData.customerName = userName
              this.formData.leadId = id
              this.formData.online = online
            })
        }
        if (followUserId && followUserId !== this.userInfo.id) {
          const roleLogos = this.userInfo.roleLogos || []
          if (roleLogos.includes(followUserRoleCode)) {
            this.$dialog
              .confirm({
                title: this.$t('提示'),
                message: this.$t('店内公共池已存在同号码线索，不允许重复创建，是否直接领取？'),
                confirmButtonColor: '#B9921A',
                confirmButtonText: this.$t('领取'),
              })
              .then(async () => {
                const result = await clueServices.receiveRecycle({ id })
                if (result) {
                  this.$toast(this.$t('领取成功'))
                  this.formData.customerName = userName
                  this.formData.leadId = id
                  this.formData.online = online
                }
              })
          } else {
            this.$dialog
              .confirm({
                title: this.$t('提示'),
                message: `${this.$t(
                  '店内公共池已存在同号码线索，不允许重复创建；由于您无原跟进同角色权限（'
                )}${this.getDictNameByCode(1014, followUserRoleCode)}${this.$t(
                  '），无法直接领取'
                )}`,
                showCancelButton: false,
                confirmButtonColor: '#B9921A',
                confirmButtonText: this.$t(
                  '确认'
                ),
              })
              .then(() => {})
              .finally(() => {
                this.formData.customerMobile = ''
              })
          }
        }
      }
    },
    // 选中车型
    onSelectModel(target){
      // 切换时清空车
      const { vehicleCode, vehicleName, vehicleNameEn, seriesName, seriesCode } = target
      if (vehicleCode!==this.formData.vehicleCode){
        this.carList = []
        this.formData.carNumber = ''
        this.formData.vinCode = ''
        this.formData.vehicleCode = vehicleCode
        this.formData.vehicleName = vehicleName
        this.formData.vehicleNameEn = vehicleNameEn
        this.carInfo = {
          seriesName,
          seriesCode,
          modelName: vehicleName,
          modelCode: vehicleCode
        }
        this.$set(this,'appointNumMap',{})
        this.appointmentTimeList = []
        if (this.formData.typeCode !== '2002002'){
          return this.getTestDriveList()
        }
      }
    },
    // 选择意向车型 type:carInfo为意向信息  driveCarInfo为上门试驾车型
    selectCarModel() {
      let initCarInfo = this.carInfo
      selectCar({ initCarInfo })
        .then(carInfo => {
          if (typeof carInfo.modelCode === 'undefined') {
            this.$toast('请选择正确的车系车型')
            return
          }
          const { modelCode, modelName, modelNameEn, seriesName, seriesCode } = carInfo
          if (modelCode !== this.formData.vehicleCode) {
            this.carList = []
            this.formData.carNumber = ''
            this.formData.vinCode = ''
            this.carInfo = carInfo
            this.formData.seriesName = seriesName
            this.formData.seriesCode = seriesCode
            this.formData.vehicleCode = modelCode
            this.formData.vehicleName = modelName
            this.formData.vehicleNameEn = modelNameEn
            this.$set(this,'appointNumMap',{})
            this.appointmentTimeList = []
            if (this.formData.typeCode !== '2002002'){
              return this.getTestDriveList()
            }
          }
          this.$refs.carInfo.validate()
        })
        .catch(() => { })
    },
    // 车
    async getTestDriveList() {
      loading.showLoading()
      const res = await baseDataServices.getTestDriveList({ 
        modelCode: this.formData.vehicleCode,
        driveFlag: false
      })
      this.carList = res.filter(({ processFlag })=> !processFlag)
      loading.hideLoading()
      return Promise.resolve(res)
    },
    async onSelectCar(car){
      const { vinCode,carNumber } = car || {}
      this.formData.carNumber = carNumber
      this.formData.vinCode = vinCode
      // 获取车辆的预约数量
      const params = {
        appointmentType: '2001001',
        vinCode,
        startDate: dayjs().format('YYYY-MM-DD'),
        endDate: dayjs().add(1,'month').format('YYYY-MM-DD'),
        isSearchDealer: true
      }
      const res = await getCalendarList(params)
      this.appointNumMap = res
  
      this.checkAppointmentDay(this.$refs.calendarRef.activeDay)
    },
    selectHour(time,currentIdx){
      // 过期时间，不允许选择
      if (this.overTimeList.includes(time)){
        this.$toast.fail('不可选择过期时间')
        return
      }
      // 超过 24:00，不允许选择
      if (time==='23:30') return
  
      if (!this.selectedTime.length){
        this.selectedTime = [time]
      } else {
        let from,to
        if (this.selectedTime.includes(time)){
          // 移除
          const idx = this.selectedTime.indexOf(time)
          this.selectedTime = this.selectedTime.slice(0,idx)
        } else {
          // 添加
          const startIdx = this.timetable.indexOf(this.selectedTime[0])
          const endIdx = this.timetable.indexOf(this.selectedTime[this.selectedTime.length-1])
          if (currentIdx<startIdx){
            from = currentIdx
            to = endIdx
          } else if (currentIdx> endIdx){
            from = startIdx
            to = currentIdx
          }
          this.selectedTime = []
          for (let index = from; index <= to; index++) {
            const time = this.timetable[index]
            this.selectedTime.push(time)
          }
        }
      }
      // 计算选中色块的高度，定位
      if (this.selectedTime.length){
        this.$nextTick(()=>{
          const startIdx = this.timetable.indexOf(this.selectedTime[0])
          const endIdx = this.timetable.indexOf(this.selectedTime[this.selectedTime.length-1]) 
          const ele = document.getElementById('dragBox') // 控制选中时间的高度
          ele.style.top = `${startIdx * 40}px`
          ele.style.height = `${(endIdx-startIdx + 1) * 40}px`
        })
      } else {
        this.formData.appointmentStartTime = ''
        this.formData.appointmentEndTime = ''
      }
    
      const activeDay = this.$refs.calendarRef.activeDay
      if (!activeDay) {
        this.$toast(this.$t('请选择日期'))
        this.formData.appointmentStartTime = ''
        this.formData.appointmentEndTime = ''
      } else {
        if (this.selectedTime.length){
          this.$nextTick(()=>{
            const startIdx = this.timetable.indexOf(this.selectedTime[0])
            this.formData.appointmentStartTime = `${activeDay} ${this.selectedTime[0]}:00`
            const ele = document.getElementById('dragBox')
            const oldHeight = Number(ele.style.height.replace('px', ''))
            const endTime = this.timetable[startIdx+oldHeight/40]
            this.formData.appointmentEndTime = `${activeDay} ${endTime}:00`
          })
        }
      }
    },
    changeDate(date){
      // 如果是今天，获取过期时间
      const today = dayjs().format('YYYY-MM-DD')
      if (date === today){
        this.getOverTimeList()
      } else {
        this.overTimeList = []
      }
      // 修改预约时间
      if (this.formData.appointmentStartTime){
        this.formData.appointmentStartTime = ''
        this.formData.appointmentEndTime = ''
        this.selectedTime = []
      }
      // 查询排程并回显
      this.checkAppointmentDay(date)
    },
    // 查询排程并回显
    async checkAppointmentDay(date){
      const params = {
        appointmentType:'2001001',
        vinCode:this.formData.vinCode,
        date,
        isSearchDealer: true
      }
      const res = await getAppointmentDay(params)

      this.appointmentTimeList = []
      
      for (let index = 0; index < res.length; index++) {
        const e = res[index]
        const [startTime,endTime] = e.appointmentTime.split('-')
        
        // 构造定位的色块
        const disabledTime = {
          left: 100 + (this.appointmentTimeList.length + 1) * 50,
          height: this.getHeight(startTime,endTime),
          top: this.getHeight('09:00',startTime),
          time: `${startTime}-${endTime}`,
          ...e
        }
        this.appointmentTimeList.push(disabledTime)
      }
    },
    getHeight(startTime,endTime){
      let start = dayjs(`2024-02-27 ${startTime}:00`)
      const end = dayjs(`2024-02-27 ${endTime}:00`)
      let flag = true
      let count = 0
      while (flag) {
        if (start.isSame(end)) {
          flag = false
        } else if (start.isBefore(end)&&(start.add(30,'minute').isAfter(end))){
          // 非整点的情况
          if (start.add(10,'minute').isSame(end)){
            count+=0.3
          } else if (start.add(20,'minute').isSame(end)){
            count+=0.6
          } else {
            count += 0.5
          }
          flag = false
        } else {
          start = start.add(30,'minute')
          count += 1
        }
        // 最多循环 20 次  避免死循环
        if (count>30){
          flag = false
        }
      }
      return count * 40
    },
    
    // 改变试驾场景
    onChangeDrive({ dictCode, dictName }) { 
      // 切换时 清除之前的信息
      if (this.formData.typeCode !== dictCode){
        this.formData.appointmentStartTime = ''
        this.formData.appointmentEndTime = ''
        this.formData.seriesCode = ''
        this.formData.seriesName = ''
        this.formData.seriesNameEn = ''
        this.formData.vehicleCode = ''
        this.formData.vehicleName = ''
        this.formData.vehicleNameEn = ''
        this.formData.carNumber = ''
        this.formData.vinCode = ''
        this.formData.driveAddress = ''
        this.selectedTime = []
        this.appointmentTimeList = []
        this.carInfo = {}
        this.$set(this,'appointNumMap',{})
        if (dictCode==='2002003'){
          this.getActivity()
        }
      }
      this.formData.typeCode = dictCode
    },
    // 上门试驾地址切换
    onAddressChange(val){
      this.$set(this,'addressObj',val)
      Object.assign(this.formData,val)
    },
    // 集中试驾活动
    async getActivity(){
      const params = { statusFlag:2 }
      const res = await CarManageServices.activityPagePost(params)
      this.activityList = (res||[]).map(item=>({
        name:item.activityTitle,
        ...item
      }))
      if (this.activityList.length === 1){
        this.onActivitySelect(this.activityList[0])
      }
    },
    onClickActivityTitle(){
      // 取消&修改预约时，不能改变活动
      if (this.activityList.length===0){
        this.$toast(this.$t('暂无可选活动'))
        return
      }
      this.isShowActivityList = true
    },
    // 选择集中试驾活动
    onActivitySelect(activityItem){
      const { activityTitle,id,activityStartTime,activityEndTime,activityAddress,activityAreaCode,activityAreaName,activityCityCode,activityCityName,activityProvinceCode,activityProvinceName } = activityItem
      this.formData.activityTitle = activityTitle
      this.formData.testDriveActivityId = id
    
      this.formData.driveAddress = activityAddress
      this.formData.driveCityCode = activityCityCode
      this.formData.driveCityName = activityCityName
      this.formData.driveDistrictCode = activityAreaCode
      this.formData.driveDistrictName = activityAreaName
      this.formData.driveProvinceCode = activityProvinceCode
      this.formData.driveProvinceName = activityProvinceName
    
      this.activeDateRange = [activityStartTime,activityEndTime]
      this.isShowActivityList = false
      this.getSeriesByActivity(id)
    },
    // 集中试驾活动可选的车系
    async getSeriesByActivity(activityId){
      const params = {
        id: activityId,
        flag: 1, // 1：车系、2：车型、3：车辆
      }
      const res = await CarManageServices.vehicleInfoPost(params)
      this.activitySeriesList = res.map(item=>({
        name: item.seriesName,
        ...item
      }))
      // 如果只有一个车系，默认选中
      if (this.activitySeriesList.length===1){
        this.formData.seriesName = this.activitySeriesList[0].seriesName
        this.formData.seriesNameEn = this.activitySeriesList[0].seriesNameEn
        this.formData.seriesCode = this.activitySeriesList[0].seriesCode
      }
    },
    // 选择集中试驾车系
    onActivitySeriesSelect(activitySeries){
      this.formData.seriesName = activitySeries.seriesName
      this.formData.seriesNameEn = activitySeries.seriesNameEn
      this.formData.seriesCode = activitySeries.seriesCode
      this.isShowActivitySeries = false
    },
    onCancel(){
      if (this.fromClueFollow){
        this.$emit('close')
        return
      }
      this.$router.go(-1)
    },
    
    onConfirm(){
      if (this.fromClueFollow){
        if (!this.formData.appointmentStartTime || !this.formData.appointmentEndTime){
          loading.hideLoading()
          return this.$toast.fail(this.$t('请选择试驾时间'))
        }
        if (!this.formData.vehicleCode||!this.formData.carNumber){
          loading.hideLoading()
          return this.$toast.fail(this.$t('请选择试驾车型及试驾车辆'))
        }
        this.$emit('confirm',{
          ...this.formData,
          appointmentTypeCode: '2001001',
          typeCode: '2002001',
        })
      } else {
        this.confirmHandler()
      }
    },
    async confirmHandler(){
      // 校验名称，手机号，车型，vin 码，排程时间
      const arr = [this.$refs.userRef.validate(), this.$refs.formRef.validate(), this.$refs.carInfo.validate()]
      if (this.formData.typeCode === '2002002'){
        arr.push(this.$refs.addressSystem.$refs.form.validate())
      }
      Promise.all(arr).then(async ()=>{
        loading.showLoading()
        if (!this.formData.appointmentStartTime || !this.formData.appointmentEndTime){
          loading.hideLoading()
          return this.$toast.fail(this.$t('请选择试驾时间'))
        }
        if (this.formData.typeCode === '2002001'&&(!this.formData.vehicleCode||!this.formData.carNumber)){
          loading.hideLoading()
          return this.$toast.fail(this.$t('请选择试驾车型及试驾车辆'))
        }
        if (this.formData.typeCode === '2002002'&&!this.formData.vehicleCode){
          loading.hideLoading()
          return this.$toast.fail(this.$t('请选择试驾车型'))
        }
        const params = {
          ...this.formData
        }
        const res = await addTestDrive(params)
        loading.hideLoading()
        // 跳转排程界面
        this.$router.push({
          name: 'Appointment'
        })
      }).catch(()=>{
        loading.hideLoading()
      })
    }
  }
}
</script>
    <style lang="less" scoped>
    .test-drive-create{
      display: flex;
      flex-direction: column;
      height: 100vh;
    }
    .test-drive-schedule{
      flex: 1;
      padding: 10px 16px 96px;
      overflow-y: auto;
      color: #0d171a;
    
        .card{
          margin-bottom: 16px;
          background: #fff;
          padding: 10px 16px;
    
          .title{
            position: relative;
            padding-left: 10px;
            height: 24px;
            font-size: 16px;
            font-family: HYQiHei, HYQiHei;
            font-weight: normal;
            color: #0D171A;
            line-height: 24px;
          }
          .title::before{
            width: 3px;
            height: 14px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            background: @yellow-text ;
          }
          .content{
            /deep/ .van-cell{
              padding: 10px 0 !important
            }
          }
        }
    
        .model-list{
          margin-top: 12px;
          padding: 0;
        }
    
        .car-model,.selected{
          margin-right: 12px;
          margin-bottom: 12px;
          cursor: pointer;
          user-select: none;
          font-size: 12px;
        }
        .car-model{
          color: rgba(13, 23, 26, 0.45);
          background: #E7E7E7;
        }
    
        .car-item,.car-selected{
          margin-right: 12px;
          margin-bottom: 12px;
          cursor: pointer;
          user-select: none;
        }
    
        .car-item{
          color: #0D171A;
          background: #F5F5F5;
        }
    
        .car-selected{
          color: #0040C5;
          background: #CEDEFF;
        }
        .selected{
          color: #B9921A;
          background: #F7ECC8;
        }
    
        .calendarBox{
          display: flex;
          width: 100%;
          overflow-x: scroll;
          height: auto;
          justify-content: space-between;
          position: relative;
          // padding: 10px 16px;
          padding: 10px 0 !important;
          .calendar-i{
            width: 14.285%;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            height: 80px;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            user-select: none;
            &.calendar-i-active{
              background-color: #0D171A;
              color: #EED484;
              border-radius: 6px;
              overflow: hidden;
            }
            .time{
              margin: 6px 0;
            }
            .calendar{
              margin: 6px 0;
            }
            .text{
              font-size: 14px;
              font-weight: 500;
            }
            
            .value{
              font-weight: bold;
            }
          }
        }
    
        /deep/ .custom-radio-group-wrap {
        margin-top: -10px;
        display: flex;
        flex-wrap: wrap;
        .custom-radio-wrap {
          margin-top: 10px;
          margin-left: 12px;
          .custom-radio {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 8px;
            height: 24px;
            color: rgba(13,23,26,0.45);
            background-color: #F5F5F5;
            border-radius: 3px;
            font-size: 12px;
            // &.checked {
            //   // border: 1px solid @yellow;
            //   // color: @yellow-text
            // }
            &.wechatCheck{
              color: #316EE2;
              background: #F2F3FF;
            }
            &.appointmentTypeCheck{
              color: #029640;
              background: rgba(2,150,64,0.1);
            }
            &.typeCheck{
              color: #0082EF;
              background: rgba(0,130,239,0.1);
            }
            &.commonCheck{
              color: #B9921A;
              background: rgba(238,212,132,0.45);
            }
            &.disabled {
              opacity: 0.5;
              cursor: not-allowed;
              background-color: #ebedf0;
              border-color: #c8c9cc
            }
          }
        }
      }
    }
    .container{
        position: relative;
        display: flex;
        .timeBox{
            position: sticky;
            left: 0;
            width: 50px;
            font-size: 12px;
            z-index: 2;
            background: #fff;
            .time{
                height: 40px;
                border-top: 1px solid rgba(13,23,26,0.05);
            }
        }
        .placeholder{
            flex: 1;
            position: relative;
            // overflow-x: auto;
            .hour{
                display: flex;
                align-items: flex-start;
                height: 40px;
                padding: 0 16px;
                user-select: none;
                border-top: 1px solid rgba(13,23,26,0.05);
                position: relative;
                z-index: 2;
            }
            .over-time{
              background: rgba(237,239,246,0.5);
            }
        }
      .time-selected{
        position: absolute;
        width: 100px;
        left: 0;
        background: rgba(238, 212, 132, 1);
        border: 1px solid rgba(238, 212, 132, 1);
        border-radius: 4px;
        z-index: 1;
      }
      .time-disabled{
        position: absolute;
        top: 40px;
        left: 0;
        width: 50px;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(220,220,220,0.3);
        z-index: 1;
        font-size: 12px;
        border: 1px solid rgba(220,220,220,1);
        border-radius: 4px;
        .ellipsis{
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .popover-action{
      width: 260px;
      padding: 12px;
      .popover-a-item{
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        .popover-a-item-label{
          color:#646566;
        }
      }
      .btn{
        border-radius: 8px;
        width: 100%;
      }
    }
    </style>
    